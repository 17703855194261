import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-company-settings-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "form-layout" }
const _hoisted_6 = { class: "timi-form-item" }
const _hoisted_7 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectSingleSearch = _resolveComponent("SelectSingleSearch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('companySettings.viewCompanySettings')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Card, {
          title: _ctx.$t('companySettings.projects'),
          class: "transparent border"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitProjectPrevent && _ctx.handleSubmitProjectPrevent(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.defaultProject')), 1),
                  _createVNode(_component_SelectSingleSearch, {
                    placeholder: _ctx.$t('placeholder.searchProject'),
                    item: {},
                    options: _ctx.projects,
                    labelProperty: "title",
                    value: { value: _ctx.projectForm.defaultProject, text: _ctx.defaultProjectTitle },
                    emptyStateText: _ctx.$t('emptyState.projects'),
                    onOnChange: _ctx.handleSetDefaultProject
                  }, null, 8, ["placeholder", "options", "value", "emptyStateText", "onOnChange"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_Button, {
                    text: _ctx.$t('button.save'),
                    primary: true,
                    raised: true,
                    onOnClick: _ctx.handleSaveProject
                  }, null, 8, ["text", "onOnClick"])
                ])
              ], 32)
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ])
    ])
  ]))
}